import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    title: any;
    location: any;
    routerSubscription: any;

    constructor(private router: Router,private meta: Meta, private pagetitle: Title) {
    }

    ngOnInit(){
        this.title.setTitle('Werkbite - AI-Powered Fintech Solutions for Businesses and Consumers');
        this.meta.addTags([
          { name: 'description', content: 'Discover Werkbite, the AI-powered platform specializing in Fintech solutions, predictive analytics, and business transformation. From personal finance to custom software development, Werkbite helps businesses scale with cutting-edge technology and tailored strategies. Explore how we innovate in healthcare, retail, finance, and technology industries.' },
          { name: 'keywords', content: 'Werkbite, AI-powered solutions, Fintech services, predictive analytics, business transformation, custom software development, B2C product innovation, IT consulting, Azure-sponsored company, machine learning, data-driven marketing, omnichannel adoption, healthcare AI, retail innovation, finance technology, technology solutions' },
          { name: 'author', content: 'Werkbite AI Solutions Team' },
        ]);
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}