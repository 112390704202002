import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-team-page',
    templateUrl: './team-page.component.html',
    styleUrls: ['./team-page.component.scss']
})
export class TeamPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Our Team'
        }
    ]
    singleScientistBox: scientistBoxContent[] = [
        // {
        //     img: 'assets/img/scientist/img4.png',
        //     title: 'Ashish Kumar Singh',
        //     designation: 'Founder',
        //     facebookLink: '#',
        //     facebookIcon: 'bx bxl-facebook',
        //     twitterLink: '#',
        //     twitterIcon: 'bx bxl-twitter',
        //     instagramLink: '#',
        //     instagramIcon: 'bx bxl-instagram',
        //     linkedinLink: '#',
        //     linkedinIcon: 'bx bxl-linkedin'
        // },
        // {
        //     img: 'assets/img/scientist/img3.png',
        //     title: 'Bhumika Singh',
        //     designation: 'Founder',
        //     facebookLink: '#',
        //     facebookIcon: 'bx bxl-facebook',
        //     twitterLink: '#',
        //     twitterIcon: 'bx bxl-twitter',
        //     instagramLink: '#',
        //     instagramIcon: 'bx bxl-instagram',
        //     linkedinLink: '#',
        //     linkedinIcon: 'bx bxl-linkedin'
        // },
        {
            img: 'assets/img/scientist/img2.png',
            title: 'Aniket Kasar',
            designation: 'Analytical Solutions',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        },
        {
            img: 'assets/img/scientist/img4.png',
            title: 'Maulin Shah',
            designation: 'Data Scientist',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        },
        {
            img: 'assets/img/scientist/img4.png',
            title: 'Dheeraj Kumar',
            designation: 'UI/UX Designer',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        }
        ,
        {
            img: 'assets/img/scientist/img4.png',
            title: 'Rohit Kumar',
            designation: 'UI/UX Designer',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        },
        {
            img: 'assets/img/scientist/img1.png',
            title: 'Barkha Singh',
            designation: 'Mentor',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        },
        {
            img: 'assets/img/scientist/img4.png',
            title: 'Rajiv Kumar',
            designation: 'UI/UX Designer',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        }
    ]

}
class pageTitle {
    title : string;
}
class scientistBoxContent {
    img: string;
    title: string;
    designation: string;
    facebookLink: string;
    facebookIcon: string;
    twitterLink: string;
    twitterIcon: string;
    instagramLink: string;
    instagramIcon: string;
    linkedinLink: string;
    linkedinIcon: string;
}