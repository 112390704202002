<section class="page-title-area">
    <div class="container">
        <div class="page-title-content"
            *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg"
            alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png"
            alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png"
            alt="image"></div>
</section>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item"
                    *ngFor="let Accordion of faqAccordion;">
                    <a class="accordion-title" href="javascript:void(0)">
                        <i class='bx bx-chevron-down'></i>
                        {{Accordion.questions}}
                    </a>

                    <div class="accordion-content">
                        <p>At Werkbite, we prioritize the privacy and security
                            of our users' personal information. This policy
                            outlines how we collect, use, disclose, and
                            safeguard data when you interact with our services.
                            As an IT service provider specializing in innovative
                            application and platform development, we are
                            committed to ensuring transparency and trust.</p><p>

                            1. Information We Collect
                            We may collect personal data such as name, email
                            address, contact information, and usage data when
                            you interact with our applications, websites, or
                            services. This data helps us enhance user
                            experience, improve service functionality, and offer
                            tailored solutions.</p><p>

                            2. How We Use Your Information
                            The data collected is used for:

                            Providing and maintaining our services.

                            Personalizing user experience.

                            Improving platform performance and security.

                            Communicating updates, marketing, or other relevant
                            information.

                            Complying with legal obligations and protecting user
                            rights.</p>

                        <p>3. Sharing Your Information
                            Werkbite does not sell or share personal data with
                            third-party companies for direct marketing purposes.
                            Data may be shared with trusted partners, such as
                            cloud service providers and analytics tools, to
                            enhance service delivery, always ensuring compliance
                            with relevant data protection regulations.</p>

                        <p> 4. Data Security
                            We employ stringent security measures, including
                            encryption and secure access protocols, to protect
                            user data from unauthorized access, disclosure,
                            alteration, or destruction.</p>

                        <p> 5. User Rights
                            You have the right to access, modify, or delete your
                            personal information stored with us. Requests can be
                            directed to our support team, and we strive to
                            address all inquiries promptly.</p>

                        <p>6. Cookies and Tracking Technologies
                            Our websites and applications may use cookies to
                            optimize user experience. These tools help analyze
                            user behavior and improve service offerings. Users
                            can manage cookie preferences through browser
                            settings.</p>

                        <p>7. Changes to This Policy
                            Werkbite may update this Privacy Policy as
                            necessary. We encourage users to review it
                            periodically to stay informed about how we are
                            protecting their data.</p>

                        <p> 8. Contact Us
                            For any questions or concerns regarding this policy,
                            please contact our support team at support
                            werkbite.com.</p>
                        <p>
                            By using our services, you acknowledge and agree to
                            this Privacy Policy. Your trust is paramount to us,
                            and we are dedicated to maintaining the highest
                            standards of data privacy and protection.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>