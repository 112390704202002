import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-buywork-case-studies-details-page',
    templateUrl: './buywork-case-studies-details-page.component.html',
    styleUrls: ['./buywork-case-studies-details-page.component.scss']
})
export class BuyWorkCaseStudiesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Buywork : Case Studies Details',
            subTitle: 'Revolutionizing Local Services with Geolocation-Powered Vendor Connections',
        }
    ]

    caseStudiesDetailsImage: Image[] = [
        {
            img: 'assets/img/projects/projects-details1.jpg'
        }
    ]
    caseStudiesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Connecting Communities, Empowering Services – Your Local Solutions Just a Tap Away',
            title1: 'Transforming Everyday Needs with BuyWork',
            paragraph1: 'BuyWork is a next-generation platform that bridges the gap between customers and skilled local service providers. Designed to address the challenges of finding reliable vendors nearby, BuyWork leverages cutting-edge geolocation technology to connect users with service providers in their immediate vicinity. Whether it s plumbing, cleaning, tutoring, or freelance work, BuyWork offers a seamless solution for all your everyday needs. With a focus on affordability, transparency, and user satisfaction, the platform provides real-time task tracking, proposal comparisons, and a secure communication channel to ensure a hassle-free experience. BuyWork not only empowers customers but also creates meaningful opportunities for local vendors, fostering a thriving community.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Empowering Vendors, Enhancing Lives – Redefining Service Access with BuyWork',
            paragraph2: 'BuyWork is not just a platform—it s a movement to empower communities by simplifying access to skilled services. With its user-friendly interface and community-centric approach, BuyWork creates a dynamic ecosystem where users can effortlessly connect with reliable vendors for their specific needs. Whether it’s finding a trusted electrician, scheduling a home cleaning session, or hiring a personal trainer, BuyWork’s diverse service categories cater to every lifestyle. For service providers, the platform serves as a gateway to opportunities, enabling them to reach more clients, build their reputation, and thrive in the digital economy. By prioritizing trust, efficiency, and value, BuyWork is setting a new standard for local services.',
            paragraph3: ' BuyWork redefines the way people access and deliver services by focusing on inclusivity and efficiency. The platform not only makes it easy for users to find skilled professionals but also empowers vendors to showcase their expertise and build lasting customer relationships. With a robust review system, users gain confidence in their choices, while vendors can improve their profiles based on transparent feedback. BuyWork’s intuitive interface, combined with its focus on hyper-localized services, ensures that every interaction is seamless and beneficial for both parties. By integrating modern solutions like task tracking, real-time bidding, and flexible payment options, BuyWork transforms everyday challenges into hassle-free experiences',
            title3: 'Results',
            paragraph4: 'BuyWork delivers significant results by transforming the way users access and vendors deliver services. It enhances service accessibility, allowing users to quickly find and connect with reliable local providers, reducing the hassle and time associated with traditional methods. For vendors, BuyWork offers a platform to showcase their skills and gain visibility, resulting in increased work opportunities and fostering local economic growth. The platform’s intuitive features, such as real-time task tracking, bidding, and transparent proposal comparisons, not only streamline the service process but also boost user engagement. This comprehensive approach ensures faster service delivery, higher satisfaction, and a thriving ecosystem that benefits both users and service providers.',
        }
    ]
    caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'The Field of Data Science'
        },
        {
            title: 'The Problem'
        },
        {
            title: 'The Solution'
        },
        {
            title: 'The Skills'
        },
        {
            title: 'Statistics'
        },
        {
            title: 'Mathematics'
        }
    ]
    caseStudiesDetailsInfo: Info[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Client',
            subTitle: 'Werkbite',
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'India',
        },
        {
            icon: 'bx bx-purchase-tag',
            title: 'Technologies',
            subTitle: 'FlutterFlow, Azure, .Net, Data Science',
        },
        {
            icon: 'bx bx-check',
            title: 'Completed',
            subTitle: '28 April 2024',
        },
        {
            icon: 'bx bx-globe',
            title: 'Website',
            subTitle: 'www.buywork.in',
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}
class Image {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    img1 : string;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4: string;
}
class ImportantFacts {
    title : string;
}
class Info {
    icon : string;
    title : string;
    subTitle : string;
}