import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-policy-page',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Privacy Policy'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Details'
            
        }
        
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    
}