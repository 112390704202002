import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-term-condition-page',
    templateUrl: './terms-condition.component.html',
    styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Terms & Condition'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Details'
            
        }
        
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    
}