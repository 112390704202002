<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img style="width: 93px;" src="assets/img/logo.png"
                            alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img style="width: 93px;" src="assets/img/logo.png"
                        alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="/"
                                class="nav-link">Home</a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Data
                                        Science</a></li>

                                <li class="nav-item"><a routerLink="/ai-startup"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">AI
                                        Startup</a></li>

                                <li class="nav-item"><a
                                        routerLink="/machine-learning"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Machine
                                        Learning</a></li>

                                <li class="nav-item"><a routerLink="/big-data"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Big
                                        Data Startup</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/about-us"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About
                                Us</a></li>

                        <li class="nav-item"><a href="javascript:void(0)"
                                class="nav-link">Services <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Services</a></li>

                                <li class="nav-item"><a
                                        routerLink="/services-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Services
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)"
                                class="nav-link">Case Studies <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a
                                        routerLink="/case-studies"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Case
                                        Studies</a></li>

                                <li class="nav-item"><a
                                        routerLink="/case-studies-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Case
                                        Studies Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)"
                                class="nav-link">Contact Us <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">

                                <li class="nav-item"><a routerLink="/gallery"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <!-- 
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses</a></li>

                                        <li class="nav-item"><a routerLink="/courses-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>

                                        <li class="nav-item"><a routerLink="/events-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li> -->

                                <li class="nav-item"><a routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <!-- <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li> -->

                                <li class="nav-item"><a routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="col-lg-6 col-sm-6 col-md-4"><img
                                    style="width: 164px;"
                                    alt="image"
                                    src="assets/img/partner/img1.png">
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-4"><img
                                    style="width: 164px;"
                                    alt="image"
                                    src="assets/img/partner/img5.png">
                            </div>
                        </div>
                        
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i
                                    class="flaticon-right"></i>Get
                                Started<span></span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="col-lg-6 col-sm-6 col-md-4"><img
                                    style="width: 164px;"
                                    alt="image"
                                    src="assets/img/partner/img1.png">
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-4"><img
                                    style="width: 164px;"
                                    alt="image"
                                    src="assets/img/partner/img5.png">
                            </div>
                        </div>
                       

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i
                                    class="flaticon-right"></i>Get
                                Started<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>