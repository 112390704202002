<section class="page-title-area">
    <div class="container">
        <div class="page-title-content"
            *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg"
            alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png"
            alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png"
            alt="image"></div>
</section>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item"
                    *ngFor="let Accordion of faqAccordion;">
                    <a class="accordion-title" href="javascript:void(0)">
                        <i class='bx bx-chevron-down'></i>
                        {{Accordion.questions}}
                    </a>

                    <div class="accordion-content">
                        <p>Welcome to Werkbite. By accessing and using our
                            services, you agree to comply with the following
                            terms and conditions. These terms outline the rules
                            and regulations for the use of Werkbite’s website,
                            applications, and services. Please read them
                            carefully.</p>

                        <p> 1. Acceptance of Terms
                            By using Werkbite’s services, you confirm that you
                            have read, understood, and agree to be bound by
                            these terms and any additional guidelines or
                            policies referenced herein.</p>

                        <p> 2. Services Offered
                            Werkbite provides IT services, including application
                            and platform development. Our services may include
                            but are not limited to consulting, software
                            development, and technical support. We reserve the
                            right to modify or discontinue any aspect of our
                            services at our discretion.</p>

                        <p> 3. User Responsibilities
                            Users are responsible for maintaining the
                            confidentiality of their account information and for
                            any activities under their account. You agree to use
                            Werkbite’s services only for lawful purposes and in
                            a manner that does not infringe on the rights of or
                            restrict the use and enjoyment of the service by any
                            other party.</p>

                        <p> 4. Intellectual Property
                            All content, trademarks, and data on Werkbite’s
                            platforms, including but not limited to text,
                            graphics, and software, are the property of Werkbite
                            and protected by intellectual property laws.
                            Unauthorized use or distribution of such content is
                            prohibited.</p>

                        <p> 5. Limitation of Liability
                            Werkbite and its affiliates shall not be liable for
                            any indirect, incidental, or consequential damages
                            arising from the use or inability to use our
                            services. This includes but is not limited to data
                            loss, service interruptions, or unauthorized
                            access.</p>

                        <p> 6. Privacy
                            Our use of any personal data provided by you is
                            governed by our Privacy Policy. By using our
                            services, you consent to the collection and use of
                            your data as outlined in our Privacy Policy.</p>

                        <p> 7. Modifications to Terms
                            Werkbite reserves the right to modify these terms at
                            any time. Any changes will be communicated to users,
                            and continued use of our services constitutes
                            acceptance of the modified terms.</p>

                        <p> 8. Termination
                            Werkbite reserves the right to terminate or suspend
                            your access to our services at any time, with or
                            without notice, for any violation of these terms or
                            other reasons deemed necessary.</p>

                        <p> 9. Governing Law
                            These terms shall be governed by and construed in
                            accordance with the laws of the jurisdiction in
                            which Werkbite operates. Any disputes arising from
                            these terms shall be resolved in the appropriate
                            courts within that jurisdiction.</p>

                        <p> 10. Contact Information
                            For any questions or concerns regarding these terms,
                            please contact us at support werkbite.com.</p>

                        <p> By using our services, you acknowledge that you have
                            read, understood, and agree to these Terms &
                            Conditions. Thank you for choosing Werkbite as your
                            trusted IT service provider.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>